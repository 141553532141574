import React, { useEffect, useState } from 'react';
import { ErrorCode, FxRateAlert } from 'types';
import {
	fetchRateAlert as _fetchRateAlert,
	cancelRateAlert as _cancelRateAlert
} from 'services/rate-alerts';
import { Helmet } from 'react-helmet-async';
import { Page, Loader } from 'components/common';
import { useLocation, useParams } from 'react-router-dom';
import { parseQueryParams } from 'utils/url';
import { CancelRateAlertForm } from './CancelRateAlertForm';
import { RateAlertErrorPage } from '../RateAlertErrorPage';

interface CancelRateAlertProps {
	fetchRateAlert?: typeof _fetchRateAlert;
	cancelRateAlert?: typeof _cancelRateAlert;
}

export const CancelRateAlert = (props: CancelRateAlertProps) => {
	const { fetchRateAlert = _fetchRateAlert, cancelRateAlert = _cancelRateAlert } = props;
	const [isPending, setIsPending] = useState(true);
	const [errorCode, setErrorCode] = useState<ErrorCode>();
	const [fxRateAlert, setFxRateAlert] = useState<FxRateAlert>();
	const location = useLocation();
	const { token } = parseQueryParams(location.search);
	const { orderId } = useParams<{ orderId: string }>();

	useEffect(() => {
		if (!orderId || !token) {
			setIsPending(false);
			setErrorCode('TOKEN_INVALID');
		} else {
			fetchRateAlert(orderId, token)
				.then(data => {
					setFxRateAlert(data);
					if (data.status !== 'ACTIVE_NOTIFIED') {
						setErrorCode('RATE_ALERT_NOT_ACTIVE');
					}
				})
				.catch((errorCode: ErrorCode) => setErrorCode(errorCode))
				.finally(() => setIsPending(false));
		}
	}, [fetchRateAlert, orderId, token]);

	return (
		<Page>
			<Helmet title="Cancel rate alert" />
			{isPending ? (
				<Loader />
			) : (
				<>
					{errorCode ? (
						<RateAlertErrorPage errorCode={errorCode} />
					) : (
						<CancelRateAlertForm
							cancelRateAlert={cancelRateAlert}
							fxRateAlert={fxRateAlert}
							orderId={orderId}
							token={token}
						/>
					)}
				</>
			)}
		</Page>
	);
};
