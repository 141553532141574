import React, { useState, useEffect } from 'react';
import { layout, LayoutProps } from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { mq, styled, typography, visuallyHidden } from 'utils/styles';
import { MenuIcon, ChevronIcon, CloseIcon, GlobalIcon } from 'components/icons';
import { ClientOnly, Flex, Grid } from 'components/common';
import { LanguageSelect } from 'components/form';
import { event } from 'utils/logger';
import { EVENTS, SendEvent } from 'utils/logging/types';
import { Language, useTranslation } from 'utils/i18next';
import { getStaticFileURL } from 'utils/url';
import { useBreakpoint, useOutsideClick } from 'hooks';
import { useUserLocation } from 'context/userLocation';
import { MenuItem, HeaderContext } from 'types';
import { NavBar } from './NavBar';
import { NavGroup } from './NavGroup';
import { BurgerMenu } from './BurgerMenu';
import { Link } from 'react-router-dom';
import { HiddenForExternalApps } from '../common/HiddenForExternalApps';

export const StyledHeader = styled.header`
	position: sticky;
	top: 0;
	flex-shrink: 0;
	z-index: 100;

	&::after {
		background: linear-gradient(to bottom, #d7d8d6, rgba(255, 255, 255, 0));
		bottom: -4px;
		content: '';
		display: block;
		height: 4px;
		position: absolute;
		left: 0;
		right: 0;
		z-index: -1;
	}
`;

const PrimaryHeader = styled.div`
	background: white;
	color: black;
	border-bottom: 1px solid ${({ theme }) => theme.colors.grey3};
`;

const FlexGrid = styled(Grid)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 12px;
	height: 70px;
`;

const StyledFlex = styled(Flex)`
	position: relative;
	height: 100%;
`;

const GetStartedBtn = styled.button<{ slide: boolean }>`
	position: absolute;
	left: 0;
	right: 0;
	z-index: 1;
	background: white;
	display: flex;
	border: none;
	align-items: center;
	justify-content: flex-end;
	transition:
		top 1s,
		bottom 1s;
	top: ${({ slide }) => (slide ? '-70px' : 0)};
	bottom: ${({ slide }) => (slide ? '70px' : 0)};
	cursor: pointer;
	white-space: nowrap;
	gap: 8px;
	width: 100%;

	${typography.s5};

	&:hover {
		text-decoration: underline;
	}

	${mq({
		justifyContent: ['center', 'flex-end']
	})}
`;

const HiddenText = styled.span(visuallyHidden);

const LanguageSelectContainer = styled('div', { shouldForwardProp })<LayoutProps>`
	padding-left: 12px;
	border-left: 1px solid ${({ theme: { colors } }) => colors.grey4};
	z-index: 1001;

	${layout}
`;

const BurgerMenuBtn = styled.button`
	background: 0 0;
	border: 0;
	font-size: 18px;
	padding: 0 12px 0 0;
	line-height: 1;
	cursor: pointer;

	${mq({
		display: ['block', 'block', 'none'],
		position: ['relative', 'relative', 'static'],
		zIndex: [1003, 1003, 'auto']
	})}
`;

const HSBCLogo = styled.img`
	display: block;
	height: 19.5px;
`;

interface HeaderProps {
	sendEvent?: SendEvent;
}

export const Header = ({ sendEvent = event }: HeaderProps) => {
	const { bp } = useBreakpoint();
	const { t, i18n } = useTranslation();
	const [userLocation, setUserLocation] = useUserLocation();
	const [showMenu, setShowMenu] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const [headerContext, setHeaderContext] = useState<HeaderContext | null>(null);
	const ref = useOutsideClick<HTMLDivElement>(() => {
		setShowMenu(false);
		setHeaderContext(null);
	});

	useEffect(() => {
		if (bp !== 'mobile' && bp !== 'tablet') {
			setIsMenuOpen(false);
		}
	}, [bp]);

	const burgerMenuItems: MenuItem[] = [
		{
			key: 'personal',
			label: t('header.personal'),
			onClick: userLocation === 'UK' ? () => setHeaderContext('personal') : undefined,
			href:
				userLocation === 'UK'
					? undefined
					: 'https://internationalservices.hsbc.com/global-directory',
			trackingEvent:
				userLocation === 'UK'
					? undefined
					: EVENTS.PREDEFINED_EVENTS.CLICK_ON_PERSONAL_REFFERAL
		},
		{
			key: 'business',
			label: t('header.business'),
			onClick: userLocation === 'OTHER' ? undefined : () => setHeaderContext('business'),
			href:
				userLocation === 'OTHER'
					? 'https://www.business.hsbc.com/international-markets'
					: undefined,
			trackingEvent:
				userLocation === 'OTHER'
					? EVENTS.PREDEFINED_EVENTS.CLICK_ON_BUSINESS_REFFERAL
					: undefined
		},
		{
			key: 'language',
			icon: <GlobalIcon />,
			label: t('labels.language'),
			submenu: [
				{
					label: t('languages.en'),
					onClick: () => i18n.changeLanguage(Language.ENGLISH),
					isActive: i18n.language === Language.ENGLISH
				},
				{
					label: t('languages.es'),
					onClick: () => i18n.changeLanguage(Language.SPANISH),
					isActive: i18n.language === Language.SPANISH
				},
				{
					label: t('languages.fr'),
					onClick: () => i18n.changeLanguage(Language.FRENCH),
					isActive: i18n.language === Language.FRENCH
				},
				{
					label: t('languages.tr'),
					onClick: () => i18n.changeLanguage(Language.TURKISH),
					isActive: i18n.language === Language.TURKISH
				},
				{
					label: t('languages.zh-HK'),
					onClick: () => i18n.changeLanguage(Language.CHINESE_HK),
					isActive: i18n.language === Language.CHINESE_HK
				},
				{
					label: t('languages.zh-CN'),
					onClick: () => i18n.changeLanguage(Language.CHINESE),
					isActive: i18n.language === Language.CHINESE
				}
			]
		}
	];

	return (
		<StyledHeader ref={ref}>
			<PrimaryHeader>
				<FlexGrid>
					<Flex gridGap={['none', 'none', 'xlarge']} alignItems="center">
						<HiddenForExternalApps>
							<BurgerMenuBtn
								type="button"
								aria-expanded={isMenuOpen}
								aria-controls="nav-wrapper"
								data-testid="burger-menu-btn"
								onClick={() => setIsMenuOpen(open => !open)}
							>
								{isMenuOpen ? (
									<CloseIcon aria-hidden="true" />
								) : (
									<MenuIcon aria-hidden="true" />
								)}
								<HiddenText>{t('labels.menu')}</HiddenText>
							</BurgerMenuBtn>
							{isMenuOpen && (
								<BurgerMenu menuItems={burgerMenuItems} sendEvent={sendEvent} />
							)}
						</HiddenForExternalApps>
						<Link
							to="/"
							title="Home"
							aria-label={t('aria.homepage')}
							onClick={() => sendEvent(EVENTS.PREDEFINED_EVENTS.CLICK_ON_LOGO)}
						>
							<HSBCLogo
								src={getStaticFileURL('assets/hsbc_logo.svg')}
								alt="HSBC logo"
								aria-hidden="true"
							/>
						</Link>
						<LanguageSelectContainer
							data-testid="language-selector"
							display={['none', 'none', 'block']}
						>
							<LanguageSelect withBorder={false} />
						</LanguageSelectContainer>
					</Flex>

					<HiddenForExternalApps>
						<StyledFlex alignItems="center" gridGap="medium">
							<ClientOnly>
								<GetStartedBtn
									onClick={() => setShowMenu(prev => !prev)}
									slide={showMenu}
									tabIndex={showMenu ? -1 : 0}
								>
									{bp === 'mobile'
										? t('header.title.mobile')
										: t('header.title.desktop')}
									<ChevronIcon size="small" />
								</GetStartedBtn>
								<NavBar
									sendEvent={sendEvent}
									context={headerContext}
									onContextChange={setHeaderContext}
									region={userLocation}
									tabIndex={showMenu ? 0 : -1}
								/>
							</ClientOnly>
						</StyledFlex>
						{headerContext && userLocation !== 'OTHER' && (
							<NavGroup
								context={headerContext}
								region={userLocation}
								onRegionChange={setUserLocation}
								onClose={() => setHeaderContext(null)}
								sendEvent={sendEvent}
							/>
						)}
					</HiddenForExternalApps>
				</FlexGrid>
			</PrimaryHeader>
		</StyledHeader>
	);
};
