import React, { InputHTMLAttributes } from 'react';
import { css, styled } from 'utils/styles';
import { YesThick } from 'components/icons';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
	id: string;
	label: string;
	secondaryLabel?: string;
	isError?: boolean;
}

const CheckboxContainer = styled.label`
	display: flex;
	gap: 12px;
`;

const CheckboxInput = styled.div<Pick<CheckboxProps, 'isError'>>(
	({ theme: { colors }, isError = false }) => css`
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		flex-shrink: 0;
		width: 32px;
		height: 32px;
		background-color: ${isError ? colors.rag.redTint : 'none'};
		border: 1px solid ${isError ? colors.rag.red : colors.grey5};

		&:focus-within {
			outline: rgb(16, 16, 16) auto 1px;
		}

		svg {
			width: 18px;
			height: 18px;
		}
	`
);

const HiddenInput = styled.input`
	border: 0;
	clip: rect(0px, 0px, 0px, 0px);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const CheckboxLabel = styled.span`
	cursor: pointer;
	display: inline-block;
	margin: 4px 0;
	line-height: 1.5;
	font-size: ${({ theme }) => theme.fontSize.medium};
`;

const CheckboxSecondaryLabel = styled(CheckboxLabel)`
	line-height: 1.4;
	font-size: ${({ theme }) => theme.fontSize.small};
`;

export const Checkbox = (props: CheckboxProps) => {
	const { label, secondaryLabel, isError, ...inputProps } = props;

	return (
		<CheckboxContainer htmlFor={inputProps.id}>
			<CheckboxInput isError={isError}>
				<HiddenInput type="checkbox" {...inputProps} />
				{inputProps.checked && <YesThick />}
			</CheckboxInput>
			<div>
				<CheckboxLabel>{label}</CheckboxLabel>
				{secondaryLabel && (
					<CheckboxSecondaryLabel>{secondaryLabel}</CheckboxSecondaryLabel>
				)}
			</div>
		</CheckboxContainer>
	);
};
