import { AxiosError, isAxiosError } from 'axios';
import { ErrorCode } from 'types';

const getAuthTokenErrorCode = (tokenAuthorization?: string): ErrorCode => {
	switch (tokenAuthorization) {
		case 'NEW_TOKEN':
		case 'FIRST_TOKEN':
		case 'AWAITING_VERIFY':
			return 'TOKEN_EXPIRED';

		case 'NOT_AUTHORIZED':
		case 'COULD_NOT_AUTHENTICATE':
			return 'TOKEN_INVALID';

		default:
			return 'TOKEN_INVALID';
	}
};

const getErrorCode =
	(getApiErrorCode: (e: AxiosError) => ErrorCode | undefined) =>
	(e: unknown): ErrorCode => {
		if (!isAxiosError(e)) {
			return 'UNEXPECTED_ERROR';
		}

		if (!e.response) {
			return 'NETWORK_ERROR';
		}

		console.log(e.response);

		const apiErrorCode = getApiErrorCode(e);

		if (apiErrorCode) {
			return apiErrorCode;
		}

		return 'UNEXPECTED_ERROR';
	};

const getConfirmRateAlertEmailError = (e: AxiosError): ErrorCode | undefined => {
	const status = e.response?.status;

	switch (status) {
		case 400:
			return 'TOKEN_COULD_NOT_CONFIRM';
	}
};

const getCancelRateAlertError = (e: AxiosError): ErrorCode | undefined => {
	const status = e.response?.status;
	const tokenAuthorizationHeader: string | undefined = e.response?.headers['token_authorization'];

	switch (status) {
		case 400:
			return 'RATE_ALERT_INVALID_ID'; // or "Could not cancel rate alert with id: xx, due to actual status."
		case 401:
			return getAuthTokenErrorCode(tokenAuthorizationHeader);
		case 404:
			return 'RATE_ALERT_NOT_FOUND';
	}
};
export const handleCancelRateAlertError = (e: unknown) => {
	throw getErrorCode(getCancelRateAlertError)(e);
};
export const handleCancelAllRateAlertError = (e: unknown) => {
	throw getErrorCode(getCancelRateAlertError)(e);
};

export const handleFetchRateAlertError = (e: unknown) => {
	throw getErrorCode(getCancelRateAlertError)(e);
};
export const handleConfirmRateAlertEmailError = (e: unknown) => {
	throw getErrorCode(getConfirmRateAlertEmailError)(e);
};
