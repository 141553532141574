import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Header, Page, Status } from 'components/common';

export const NotFound = () => (
	<Status code={404}>
		<Page>
			<Helmet title="Not Found" />
			<div>
				<Header>
					<h1>Oops! It looks like you’re lost…</h1>
				</Header>
				<div>
					<p>The page you requested may have moved, been deleted or never existed.</p>
					<p>
						Please use the navigation at the top of the page or click the link below to
						visit the homepage.
					</p>
				</div>
				<div>
					<Link to="/">
						{' '}
						<span>
							<span aria-hidden="true"></span>
							<span>Homepage</span>
						</span>
					</Link>
				</div>
			</div>
		</Page>
	</Status>
);

export default NotFound;
