import React from 'react';
import { ErrorCode } from 'types';
import { Header } from 'components/common';
import { useTranslation } from 'utils/i18next';

interface RateAlertErrorPageProps {
	errorCode: ErrorCode;
}

export const RateAlertErrorPage = (props: RateAlertErrorPageProps) => {
	const { errorCode } = props;
	const { t } = useTranslation();
	const title = t(`rateAlert.errors.${errorCode}.title`);
	const description = t(`rateAlert.errors.${errorCode}.description`);

	return (
		<>
			{title && (
				<Header>
					<h1>{title}</h1>
				</Header>
			)}
			{description && <p>{description}</p>}
		</>
	);
};
