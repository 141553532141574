import qs from 'qs';
import { get, trimStart } from 'lodash';
import { isCurrencyZoneDomain } from './dom/window';
import { Env, UserLocation } from '../types';

export const parseQueryParams = <T = string>(search: string) =>
	qs.parse(search, { ignoreQueryPrefix: true }) as {
		[key: string]: T | undefined;
	};

export const stringifyQueryParams = (obj = {}) => qs.stringify(obj, { skipNulls: true });

export const getStaticFileURL = (url: string) => {
	const path = trimStart(url, '/');
	return isCurrencyZoneDomain() ? `/${path}` : `/currency-zone/${path}`;
};

export const getUserLocation = (): UserLocation => {
	const supportedRegions = ['UK', 'HK'];
	// @ts-ignore
	const _region = globalThis?.utag?.data?.current_country_code;
	const region = _region === 'GB' ? 'UK' : _region;

	return supportedRegions.includes(region) ? region : 'OTHER';
};
export const getEnv = (g = globalThis): Env => {
	const getOriginUrl = () => get(g, 'location.origin', '');

	const isLocalhost = () =>
		getOriginUrl() === 'http://localhost:8080' || getOriginUrl() === 'http://localhost:8085';

	const isCurrencyZoneDomain = () => {
		const host = get(g, 'location.host', '').toLowerCase();
		return host === 'currencyzone.hsbc.com' || host === 'www.currencyzone.hsbc.com';
	};

	if (isCurrencyZoneDomain()) {
		return 'prd';
	} else if (isLocalhost()) {
		return 'dev';
	} else {
		return 'uat';
	}
};
