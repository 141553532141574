import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getUserLocation } from 'utils/url';
import { UserLocation } from 'types';

interface UserLocationProviderProps {
	children: ReactNode;
	userLocation?: UserLocation;
}

export interface UserLocationContext {
	userLocation: UserLocation;
	setUserLocation: (location: UserLocation) => void;
}

const userLocationContext = createContext<UserLocationContext | undefined>(undefined);

export const UserLocationProvider = (props: UserLocationProviderProps) => {
	const { children, userLocation: initialUserLocation = 'OTHER' } = props;
	const [userLocation, setUserLocation] = useState<UserLocation>(initialUserLocation);

	useEffect(() => {
		// [workaround] wait for utag script - todo: find better solution
		const t = setTimeout(() => setUserLocation(getUserLocation()), 1000);
		return () => clearTimeout(t);
	}, []);

	return (
		<userLocationContext.Provider value={{ userLocation, setUserLocation }}>
			{children}
		</userLocationContext.Provider>
	);
};

export const useUserLocation = (): [UserLocation, (location: UserLocation) => void] => {
	const context = useContext(userLocationContext);

	if (!context) {
		throw new Error('UserLocationContext was not provided');
	}

	const { userLocation, setUserLocation } = context;

	return [userLocation, setUserLocation];
};
