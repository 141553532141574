import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { styled } from 'utils/styles';
import { Button, Header, Page, Strong } from 'components/common';

const table1 = (
	<div>
		<table>
			<caption>
				This table lists service providers and third parties who also set cookies that help
				us provide core services and features.
			</caption>
			<thead>
				<tr>
					<th scope="col">
						<strong>Who else sets these cookies?</strong>
					</th>
					<th scope="col">
						<strong>For what purpose?</strong>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						<div role="cell">
							<div role="cell">Tealium (Tag Management)</div>
						</div>
					</td>
					<td>
						<div role="cell">
							<div role="cell">
								To allow us to control the deployment of tags (software that
								enhances our website) and also to enable the capture and respect of
								consent preferences obtained from our users
							</div>
						</div>
					</td>
				</tr>

				<tr>
					<td>
						<div role="cell">
							<div role="cell">Brightcove</div>
						</div>
					</td>
					<td>
						<div role="cell">
							<div role="cell">
								To enable the playing of video clips in the website (including
								tracking of information regarding the performance and usage of the
								videos)
							</div>
						</div>
					</td>
				</tr>
				<tr></tr>
			</tbody>
		</table>
	</div>
);

const table2 = (
	<div>
		<table>
			<caption>
				This table lists service providers and third parties who also set cookies that help
				us improve our website.
			</caption>
			<thead>
				<tr>
					<th scope="col">
						<strong>Who else sets these cookies?</strong>
					</th>
					<th scope="col">
						<strong>For what purpose?</strong>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						<div role="cell">
							<div role="cell">Heap</div>
						</div>
					</td>
					<td>
						<div role="cell">
							<div role="cell">
								To enable us to understand how you use and engage with our website
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
);

const table3 = (
	<div>
		<table>
			<caption>
				This table lists service providers and third parties who also set cookies that
				support marketing.
			</caption>
			<thead>
				<tr>
					<th scope="col">
						<strong>Who sets these cookies?</strong>
					</th>
					<th scope="col">
						<strong>For what purpose?</strong>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						<div role="cell">
							<div role="cell">Google (DoubleClick)</div>
						</div>
					</td>
					<td>
						<div role="cell">
							<div role="cell">
								To help us deliver personalised advertising based on segmentation
								informed by your browsing behaviour and to measure the performance
								of our digital advertising
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div role="cell">
							<div role="cell">Google Global Site Tag</div>
						</div>
					</td>
					<td>
						<div role="cell">
							<div role="cell">
								To help us track activity more accurately through Google’s global
								platforms
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
);

const ManageCookieButton = styled(Button)`
	margin: 2.5rem 0;
`;

export const CookieNotice = () => {
	const showConsentPreferences = () => {
		// @ts-ignore
		global?.utag?.gdpr?.showConsentPreferences();
	};
	const makeSectionLink = (pageSection: string) => `#${pageSection}`;

	return (
		<Page id={'page'}>
			<Helmet title="Cookie Notice" />
			<Header>
				<h1>Cookie Notice</h1>
			</Header>
			<div>
				<h2>Cookies on this website</h2>
				<p>
					<Strong>On this page:</Strong>
				</p>
				<ul>
					<li>
						<a
							href={makeSectionLink('whatarecookies')}
							accessKey="0"
							data-testId="what-are-cookies-link"
						>
							What are cookies?
						</a>
					</li>
					<li>
						<a
							href={makeSectionLink('cookiesweuse')}
							accessKey="1"
							data-testid={'cookiesweuse'}
						>
							Cookies we use
						</a>
					</li>
					<li>
						<a href={makeSectionLink('cookiepreferences')} accessKey="2">
							How we remember your cookie preference
						</a>
					</li>
					<li>
						<a href={makeSectionLink('browsersettings')} accessKey="3">
							Cookies and your browser settings
						</a>
					</li>
					<li>
						<a href={makeSectionLink('ourwebsites')} accessKey="4">
							Our websites
						</a>
					</li>
					<li>
						<a href={makeSectionLink('privacy')} accessKey="5">
							Cookies and your privacy
						</a>
					</li>
				</ul>
				<p>
					This Cookie notice provides you with information about the cookies we use and
					the purposes for which we use them.{' '}
				</p>
				<p>You can manage your cookie preferences at any time:</p>
				<ManageCookieButton
					id="consent_mgr"
					variant="secondary"
					aria-label="Manage cookie settings"
					onClick={() => showConsentPreferences()}
				>
					Manage cookie settings
				</ManageCookieButton>

				<h2 id={'whatarecookies'}>What are cookies?</h2>
				<p>
					Cookies are small pieces of data that websites store on your browser when you
					visit them. Cookies are useful because they allow a website to recognise your
					visit and collect information about how you use that website.
				</p>

				<h2 id={'cookiesweuse'}>The cookies we use</h2>
				<p>The cookies we use are either strictly necessary or optional.</p>

				<h3>Strictly necessary cookies</h3>
				<p>
					Strictly necessary cookies ensure that our website is able to function properly.
					We don’t have to ask for your consent to store these cookies on your browser.
					We’ve listed the categories of these strictly necessary cookies below.
				</p>

				<p>
					<Strong>Cookies that help you log on</Strong>
				</p>
				<p>
					We use these cookies to remember who you are when you log on to secure areas of
					our website. You won‘t be able to log on without them.
				</p>
				<p>
					<Strong>Cookies that help us provide core services and features</Strong>
				</p>
				<p>
					We use these cookies to provide core services and features on our website. These
					services and features won’t work without them.
				</p>
				<p>
					We work with service providers and third parties who also set these cookies on
					this website.
				</p>
				{table1}
				<p>
					<Strong>Cookies that help keep our website secure</Strong>
				</p>
				<p>
					We use these cookies to protect the security of our website, for example, to
					make sure the website is only accessed by genuine users. This helps us to keep
					you safe.
				</p>
				<p>
					<Strong>Cookies that help us detect fraud or crime</Strong>
				</p>
				<p>
					We use these cookies to help us identify suspicious behaviour on our website so
					we can protect both you and us from fraud.
				</p>
			</div>
			<h3>Optional cookies</h3>
			<p>
				We’d also like to use cookies to provide optional features, improve our website and
				support advertising and social media.{' '}
			</p>
			<p>
				We understand that not everyone likes data to be collected about them when it’s not
				strictly necessary, and so we’ll ask you to set your preferences when you first
				visit our website. To make it easier to choose which optional cookies to accept,
				we’ve organised these cookies by category. These are set out below. Where relevant,
				we’ve also explained which of our trusted partners helps us make use of these
				optional cookies.
			</p>
			<p>
				You can choose which categories you’d be happy for us to use in your cookie settings
				and make changes at any time by selecting ‘Manage cookie settings’ at the top of
				this page.
			</p>
			<p>
				<Strong>Cookies that we use to make your visit more personal</Strong>
			</p>
			<p>
				We use these cookies to ensure our optional features and services work. Our website
				will still work without them.
			</p>
			<p>This category may also include:</p>
			<ul>
				<li>
					Cookies that allow us to customise what you see on our website and where, based
					on what we know about you
				</li>
				<li>
					Cookies that help prevent fraud on other websites or services that you haven’t
					asked to use when you’re on our website
				</li>
				<span id="op_improve"></span>
				<li>
					Cookies that help keep secure other websites or services that you haven’t asked
					to use when you’re on our website
				</li>
			</ul>
			<p>
				<Strong>Cookies that help us improve our website </Strong>
			</p>
			<p>
				We use these cookies to help us understand how people use our website. We can then
				use this data to improve how our website works. For instance, we may use analytics
				providers to identify and count visits to our website and to see which pages people
				go to and what they do there.
			</p>
			<p>
				We work with service providers and third parties who also set these cookies on this
				website.
			</p>
			{table2}
			<p>
				<Strong>Cookies that support marketing</Strong>
			</p>
			<p>
				We and our partners use these cookies to understand what you’re interested in on our
				website and on social media. These cookies may also identify which other websites
				may have directed you to our website. This is so we or our partners can personalise
				our marketing to you, including online advertising and through post, email,
				telephone, text, secure message or social media. You can change your mind on how you
				receive certain types of marketing message or choose to stop receiving them at any
				time. You can find more details about this in our{' '}
				<Link to={'privacy-policy'}>Privacy policy</Link>.
			</p>
			<p>
				We work with service providers and third parties who also set these cookies on this
				website.
			</p>
			{table3}

			<h2 id={'cookiepreferences'}>How we remember your cookie preferences</h2>
			<p>
				Your preferences are stored in cookies stored on your browser. If you switch off a
				category of cookies that you’ve previously accepted, then for technical reasons
				those cookies will not be deleted.
			</p>
			<p>
				To delete cookies from your browser, we recommend that you clear your browser data.
				If you do this, or change browser, we’ll ask for your preferences again when you
				next visit our website.
			</p>

			<h2 id={'browsersettings'}>Cookie duration and your browser settings</h2>
			<p>
				The length of time for which cookies are stored on your browser varies depending on
				the cookie. Some cookies only last for your online session, whereas others will stay
				on your browser for a reasonable time afterwards. Unless we’ve given a specific time
				in the list of cookies above, the cookies set via our website will usually last up
				to 13 months after your most recent visit to our site.
			</p>
			<p>
				Some of our partners in the list above also use the same cookies to collect
				information about the use of their own or other websites. In these cases, the cookie
				can remain on your device, usually for up to 13 months from your last visit to the
				site that accessed that cookie.
			</p>
			<p>
				You can use your browser settings to delete cookies that have already been set at
				any time. You can also use your browser settings to manage cookies, for example, to
				switch off a cookie altogether. If you do this, it could mean that we can't use
				strictly necessary cookies properly and so parts of our website may not work
				correctly. For more information about how to use your browser settings to clear your
				browser data or to manage cookies, check your browser 'Help' function.
			</p>

			<h2 id={'ourwebsites'}>Our websites</h2>
			<p>
				We’re changing the way we set cookies on the websites we manage. This means that
				some of our websites may ask you to accept cookies again, or they may not yet be
				able to fully respect your preferences. We’re working on this. Please contact us if
				you have any questions.
			</p>

			<h2 id={'privacy'}>Cookies and your privacy</h2>
			<p>
				The information cookies collect, and how we use that information, may count as
				personal data. We may also be able to identify you by name, IP address or session
				ID. You have rights regarding how we collect, store and use your personal data.
			</p>
			<p>
				You can learn more about how we use your personal data in our{' '}
				<Link to={'privacy-policy'}>Privacy policy</Link>.{' '}
			</p>

			<h2>Contact us</h2>
			<p>
				To discuss service offerings from Global Banking and Markets, please speak to your
				relationship manager.
			</p>
		</Page>
	);
};

export default CookieNotice;
