import config from 'config';
import { FxRate, Range } from 'types';
import { http, HttpRequestConfig } from 'utils/http';

export const fetchFxRates = async (
	ccyPairs: string[],
	range: Range,
	httpConfig?: HttpRequestConfig
): Promise<FxRate[]> => {
	const url = `${config.apiPrefix}/ccypairsrates?ccyPairs=${ccyPairs.join(',')}&range=${range}`;
	const { data } = await http.get<FxRate[]>(url, httpConfig);
	return data.map(item => ({ ...item, range }));
};

export const fetchFxRate = async (
	ccyFrom: string,
	ccyTo: string,
	range: Range,
	config?: HttpRequestConfig
): Promise<FxRate> => {
	const [fxRate] = await fetchFxRates([ccyFrom + ccyTo], range, config);
	return fxRate;
};
