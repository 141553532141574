import React, { useState } from 'react';
import { ErrorCode } from 'types';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import { parseQueryParams } from 'utils/url';
import { useTranslation } from 'utils/i18next';
import { Button, Header, Page } from 'components/common';
import { cancelAllRateAlerts as _cancelAllRateAlerts } from 'services/rate-alerts';
import { RateAlertErrorPage } from '../RateAlertErrorPage';

interface CancelAllRateAlertsProps {
	cancelAllRateAlerts?: typeof _cancelAllRateAlerts;
}

export const CancelAllRateAlerts = (props: CancelAllRateAlertsProps) => {
	const { cancelAllRateAlerts = _cancelAllRateAlerts } = props;
	const [isPending, setIsPending] = useState(false);
	const [errorCode, setErrorCode] = useState<ErrorCode>();
	const [isCancelled, setIsCancelled] = useState(false);
	const location = useLocation();
	const { token } = parseQueryParams(location.search);
	const { t } = useTranslation();

	const handleCancel = () => {
		setIsPending(true);
		cancelAllRateAlerts(token)
			.then(() => setIsCancelled(true))
			.catch((errorCode: ErrorCode) => setErrorCode(errorCode))
			.finally(() => setIsPending(false));
	};

	const renderPage = () => {
		if (errorCode) {
			return <RateAlertErrorPage errorCode={errorCode} />;
		}

		return (
			<>
				{isCancelled ? (
					<Header>
						<h1>{t('rateAlert.cancelAll.headingSuccess')}</h1>
					</Header>
				) : (
					<>
						<Header>
							<h1>{t('rateAlert.cancelAll.heading')}</h1>
						</Header>
						<Button
							aria-label={'click to cancel alert and accept terms and conditions'}
							size="small"
							disabled={isPending || !token}
							onClick={handleCancel}
							marginBottom="large"
						>
							{t('rateAlert.cancelAll.cancelAlert')}
						</Button>
					</>
				)}
				<p>
					* For general users of this website: please{' '}
					<Link to="/privacy-policy-general-users">click here</Link> to view the Privacy
					Policy in relation to the data collected and used by this website.
				</p>
			</>
		);
	};

	return (
		<Page>
			<Helmet title="Cancel rate alert" />
			{renderPage()}
		</Page>
	);
};
