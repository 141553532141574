import config from 'config';
import { FxRateAlert, FxRateAlertRegister } from 'types';
import { http, HttpRequestConfig } from 'utils/http';
import {
	handleCancelAllRateAlertError,
	handleCancelRateAlertError,
	handleConfirmRateAlertEmailError,
	handleFetchRateAlertError
} from './errors';

export const fetchRateAlert = async (
	orderId: string,
	token: string,
	httpConfig?: HttpRequestConfig
): Promise<FxRateAlert> => {
	const url = `${config.apiPrefix}/alerts/${orderId}?token=${token}`;
	const { data } = await http.get<FxRateAlert>(url, httpConfig).catch(handleFetchRateAlertError);
	return data;
};

export const registerRateAlert = async (
	payload: FxRateAlertRegister,
	httpConfig?: HttpRequestConfig
): Promise<FxRateAlertRegister> => {
	const url = `${config.apiPrefix}/alerts`;
	const { data } = await http.post<FxRateAlertRegister>(url, payload, httpConfig);
	return data;
};

export const confirmRateAlertEmail = async (
	token: string,
	httpConfig?: HttpRequestConfig
): Promise<void> => {
	await http
		.get(`${config.apiPrefix}/alerts/confirm/${token}`, httpConfig)
		.catch(handleConfirmRateAlertEmailError);
};

export const cancelRateAlert = async (
	orderId: string,
	token?: string,
	httpConfig?: HttpRequestConfig
): Promise<void> => {
	await http
		.get(`${config.apiPrefix}/alerts/cancel/${orderId}?token=${token}`, httpConfig)
		.catch(handleCancelRateAlertError);
};

export const cancelAllRateAlerts = async (
	token?: string,
	httpConfig?: HttpRequestConfig
): Promise<void> => {
	await http
		.post(`${config.apiPrefix}/alerts/cancelall?token=${token}`, httpConfig)
		.catch(handleCancelAllRateAlertError);
};
