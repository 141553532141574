import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ErrorCode } from 'types';
import { useTranslation } from 'utils/i18next';
import { Page, Header, Loader } from 'components/common';
import { confirmRateAlertEmail as _confirmRateAlertEmail } from 'services/rate-alerts';
import { RateAlertErrorPage } from '../RateAlertErrorPage';

interface RateAlertParams {
	token: string;
}

interface ConfirmRateAlertEmailProps {
	confirmRateAlertEmail?: typeof _confirmRateAlertEmail;
}

export const ConfirmRateAlertEmail = (props: ConfirmRateAlertEmailProps) => {
	const { confirmRateAlertEmail = _confirmRateAlertEmail } = props;
	const [isPending, setIsPending] = useState<boolean>(true);
	const [errorCode, setErrorCode] = useState<ErrorCode>();
	const { t } = useTranslation();

	const { token } = useParams<RateAlertParams>();

	useEffect(() => {
		confirmRateAlertEmail(token)
			.catch((errorCode: ErrorCode) => setErrorCode(errorCode))
			.finally(() => setIsPending(false));
	}, [confirmRateAlertEmail, token]);

	return (
		<Page>
			<Helmet title="Confirm rate alert" />
			{isPending ? (
				<Loader textDelay={3000} />
			) : errorCode ? (
				<RateAlertErrorPage errorCode={errorCode} />
			) : (
				<>
					<Header>
						<h1>{t('rateAlert.confirmEmail.heading')}</h1>
					</Header>
					<p>{t('rateAlert.confirmEmail.confirmation')}</p>
					<p>{t('rateAlert.confirmEmail.notice')}</p>
				</>
			)}
		</Page>
	);
};
