import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Header } from 'components/common';
import { formatDate } from 'utils/date';
import { useTranslation } from 'utils/i18next';
import { ErrorCode, FxRateAlert } from 'types';
import { cancelRateAlert } from 'services/rate-alerts';
import { RateAlertErrorPage } from '../RateAlertErrorPage';

interface RateAlertCancelFormProps {
	cancelRateAlert: typeof cancelRateAlert;
	orderId: string;
	fxRateAlert?: FxRateAlert;
	token?: string;
}

export const CancelRateAlertForm = (props: RateAlertCancelFormProps) => {
	const { cancelRateAlert, fxRateAlert, orderId, token } = props;
	const { t } = useTranslation();
	const [isPending, setIsPending] = useState(false);
	const [errorCode, setErrorCode] = useState<ErrorCode>();
	const [isCancelled, setIsCancelled] = useState(false);

	if (!fxRateAlert) {
		return null;
	}

	const { ccyPair, rate, createdAt } = fxRateAlert;

	const handleCancel = () => {
		setIsPending(true);
		cancelRateAlert(orderId, token)
			.then(() => setIsCancelled(true))
			.catch((errorCode: ErrorCode) => setErrorCode(errorCode))
			.finally(() => setIsPending(false));
	};

	if (errorCode) {
		return <RateAlertErrorPage errorCode={errorCode} />;
	}

	return isCancelled ? (
		<>
			<Header>
				<h1>{t('rateAlert.cancel.headingSuccess')}</h1>
				<p>{t('rateAlert.cancel.confirmationEmail')}</p>
			</Header>
			<p>
				* For general users of this website: please{' '}
				<Link to="/privacy-policy-general-users">click here</Link> to view the Privacy
				Policy in relation to the data collected and used by this website.
			</p>
		</>
	) : (
		<>
			<Header>
				<h1>{t('rateAlert.cancel.heading')}</h1>
				<p>
					{t('rateAlert.cancel.alert', {
						postProcess: 'sprintf',
						sprintf: [ccyPair, rate, formatDate(createdAt)]
					})}
				</p>
			</Header>
			<Button
				aria-label={'click to cancel alert and accept terms and conditions'}
				data-testid="currency-rate-alert-cancel-button"
				size="small"
				disabled={isPending}
				onClick={handleCancel}
			>
				{t('rateAlert.cancel.cancelAlert')}
			</Button>
		</>
	);
};
