import React, { useEffect, useRef, useState } from 'react';
import { formatDateTime } from 'utils/date';
import { splitCcyPair } from 'utils/currency';
import { useTranslation } from 'utils/i18next';
import { formatAmount } from 'utils/formatters';
import { flashAnimation, HSBCTheme, mq, styled, useTheme } from 'utils/styles';
import { FxRate, Rate } from 'types';
import { Chart, EmptyBox, EmptyState, Loader } from 'components/common';
import { createChartLabel, peggedCurrencies } from 'common/chart';
import { ChartArea } from 'chart.js';

interface CurrencyRateChartProps {
	isLoading: boolean;
	fxRate: FxRate;
	onClick: (value: string) => void;
	selectedRate: string;
}

const ChartContainer = styled.div`
	position: relative;
	margin-top: ${({ theme }) => theme.space.medium};
	margin-bottom: ${({ theme }) => theme.space.large};

	${flashAnimation}
	${mq({
		height: ['200px', '250px', '270px']
	})}
`;

const thresholdPlugin = (
	theme: HSBCTheme,
	fxRate: FxRate,
	onClick: (value: string) => void,
	selectedRate: string
) => {
	const data = {
		y: 0,
		ySelectedFromClick: 0,
		draw: false
	};

	const renderLine = (
		ctx: CanvasRenderingContext2D,
		{ left, right }: ChartArea,
		linePosition: number,
		color: string
	) => {
		ctx.beginPath();
		ctx.lineWidth = 2;
		ctx.strokeStyle = color;
		ctx.setLineDash([6, 6]);
		ctx.moveTo(left, linePosition);
		ctx.lineTo(right, linePosition);
		ctx.stroke();
	};

	const renderBox = (
		ctx: CanvasRenderingContext2D,
		{ left }: ChartArea,
		boxPosition: number,
		text: string,
		boxColor: string,
		textColor: string
	) => {
		ctx.font = '16px UniversNext';
		const { width } = ctx.measureText(text);
		ctx.fillStyle = boxColor;
		ctx.fillRect(left, boxPosition - 30, width + 24, 30);
		ctx.fillStyle = textColor;
		ctx.fillText(text, left + 12, boxPosition - 9);
	};

	return {
		id: 'threshold',
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		afterEvent: (chart: any, args: any) => {
			const { scales } = chart;
			const { inChartArea } = args;
			const { type, y } = args.event;

			Object.assign(data, { y, draw: inChartArea });

			if (type === 'click' && y) {
				const rateValue = scales.yAxis.getValueForPixel(y);
				if (rateValue < scales.yAxis.max && rateValue > scales.yAxis.min) {
					rateValue && onClick(rateValue.toFixed(fxRate.decimalPlaces));

					Object.assign(data, { ySelectedFromClick: y });
				}
			}
			chart.draw();
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		afterDraw: (chart: any) => {
			const { ctx, scales, chartArea } = chart;
			const { y = 0, draw = false } = data;
			const ySelectedFromInput = selectedRate && scales.yAxis.getPixelForValue(selectedRate);
			const currentRate = fxRate.latest?.open;
			const yCurrentRatePixel = scales.yAxis.getPixelForValue(currentRate);

			if (currentRate && yCurrentRatePixel) {
				renderLine(ctx, chartArea, yCurrentRatePixel, theme.colors.grey5);
				renderBox(
					ctx,
					chartArea,
					yCurrentRatePixel,
					`Current rate: ${formatAmount(currentRate, fxRate.decimalPlaces)}`,
					theme.colors.grey5,
					theme.colors.white
				);
			}

			if (
				currentRate &&
				ySelectedFromInput <= chartArea.bottom &&
				ySelectedFromInput >= chartArea.top
			) {
				const selectedRateNum = parseFloat(selectedRate);
				const color =
					selectedRateNum < currentRate ? theme.colors.rag.red : theme.colors.data.green3;
				renderLine(ctx, chartArea, ySelectedFromInput, color);
				renderBox(
					ctx,
					chartArea,
					ySelectedFromInput,
					`Alert rate: ${formatAmount(selectedRateNum, fxRate.decimalPlaces)}`,
					color,
					theme.colors.white
				);
			}

			if (!draw || !y) return;

			const rateValue = scales.yAxis.getValueForPixel(y);
			if (rateValue) {
				renderLine(ctx, chartArea, y, theme.colors.data.blue4);
				renderBox(
					ctx,
					chartArea,
					y,
					`Desired rate: ${formatAmount(rateValue, fxRate.decimalPlaces)}`,
					theme.colors.data.blue1,
					theme.colors.white
				);
			}

			ctx.restore();
		}
	};
};

export const RateAlertChart = React.memo((props: CurrencyRateChartProps) => {
	const { isLoading, fxRate, onClick, selectedRate } = props;
	const chartRef = useRef<HTMLDivElement>(null);
	const theme = useTheme();
	const { t, i18n } = useTranslation();
	const [dataSet, setDataSet] = useState<Rate[]>([]);
	const [ccyFrom, ccyTo] = splitCcyPair(fxRate?.ccyPair || '');

	useEffect(() => {
		setDataSet(fxRate.dataSet);
	}, [fxRate]);

	const renderChart = () => {
		if (isLoading) {
			return <Loader textDelay={3000} />;
		} else if (fxRate.dataSet.length === 0) {
			return (
				<EmptyState width="100%" height="100%" justifyContent="center">
					<EmptyBox width="100%" height="90%" />
				</EmptyState>
			);
		} else {
			return (
				<Chart
					id="rate-alert-chart"
					data-testid="rate-alert-chart"
					role="img"
					config={{
						type: 'line',
						data: {
							labels: dataSet.map(({ timestamp }) =>
								createChartLabel(timestamp, fxRate.range, i18n.language)
							),
							datasets: [
								{
									data: dataSet.map(({ open }) => open),
									tension: 0.4,
									fill: true,
									backgroundColor: '#469cbe5c',
									borderColor: theme.colors.data.blue4,
									pointHoverBackgroundColor: 'transparent',
									pointHoverBorderWidth: 0,
									spanGaps: true
								}
							]
						},
						options: {
							animation: false,
							layout: {
								padding: {
									top: 30
								}
							},
							responsive: true,
							maintainAspectRatio: false,
							scales: {
								xAxis: {
									grid: {
										borderWidth: 1,
										borderColor: theme.colors.typography,
										tickColor: theme.colors.typography,
										drawOnChartArea: false,
										tickWidth: 1,
										tickLength: 8
									},
									ticks: {
										source: 'labels',
										autoSkipPadding: 24,
										align: 'start',
										padding: 4,
										maxRotation: 0,
										minRotation: 0,
										color: theme.colors.typography
									}
								},
								yAxis: {
									position: 'left',
									min: peggedCurrencies.find(ccy => ccy === fxRate?.ccyPair)
										? 0
										: undefined,
									suggestedMin: fxRate.low || undefined,
									suggestedMax: fxRate.high || undefined,
									grid: {
										borderDash: [7, 5],
										color: theme.colors.grey4,
										tickBorderDash: [],
										drawTicks: false,
										drawBorder: false,
										lineWidth: 1
									},
									ticks: {
										align: 'end',
										labelOffset: -4,
										mirror: true,
										count: 3,
										color: theme.colors.typography,
										backdropPadding: 100,
										callback: value =>
											formatAmount(
												typeof value === 'string' ? parseInt(value) : value,
												fxRate?.decimalPlaces
											)
									}
								}
							},
							elements: {
								point: {
									radius: 0
								}
							},
							plugins: {
								tooltip: {
									enabled: false,
									backgroundColor: theme.colors.white,
									bodyColor: theme.colors.typography,
									titleFont: {
										size: 16
									},
									titleMarginBottom: 0,
									titleColor: theme.colors.typography,
									footerFont: {
										size: 12,
										weight: 'normal'
									},
									footerColor: theme.colors.typography,
									footerMarginTop: 6,
									displayColors: false,
									yAlign: 'top',
									xAlign: 'left',
									borderWidth: 1,
									borderColor: theme.colors.grey4,
									caretPadding: 15,
									caretSize: 0,
									cornerRadius: 0,
									padding: 12,
									callbacks: {
										title: ctx => `1 ${ccyFrom} = ${ctx[0].raw} ${ccyTo}`,
										label: () => '',
										footer: ctx =>
											formatDateTime(
												dataSet[ctx[0].dataIndex].timestamp,
												i18n.language
											)
									}
								},
								legend: {
									display: false
								}
							},
							interaction: {
								intersect: false,
								mode: 'nearest',
								axis: 'x'
							}
						},
						plugins: [thresholdPlugin(theme, fxRate, onClick, selectedRate)]
					}}
					aria-label={t('aria.chart')}
				/>
			);
		}
	};

	return (
		<>
			<ChartContainer flash={false} ref={chartRef}>
				{renderChart()}
			</ChartContainer>
		</>
	);
});

RateAlertChart.displayName = 'RateAlertChart';
